@import "~antd/dist/antd.css";

/* .App {
} */

.logo {
	height: 32px;
  width: 32px;
	/* background: rgba(255, 255, 255, 0.2); */
	background-image: url("../../assets/512.png");
	background-size: contain;
	background-repeat: no-repeat;
	margin: 16px;
}

.header-title {
  color: #ffffff;
}

.gutter-row {
  padding: 8px 0px;
}

.gutter-col {
  padding: 2px 4px;
}

/* PADDING HELPERS */


/* PADDING HELPERS */
.p-1 {
  padding: 10px;
}
.p-2 {
  padding: 20px;
}
.p-3 {
  padding: 30px;
}

.pb-1 {
  padding-bottom: 10px;
}
.pb-2 {
  padding-bottom: 20px;
}
.pb-3 {
  padding-bottom: 30px;
}

.pt-1 {
  padding-top: 10px;
}
.pt-2 {
  padding-top: 20px;
}
.pt-3 {
  padding-top: 30px;
}

.pr-1 {
  padding-right: 10px;
}
.pr-2 {
  padding-right: 20px;
}
.pr-3 {
  padding-right: 30px;
}

.pl-1 {
  padding-left: 10px;
}
.pl-2 {
  padding-left: 20px;
}
.pl-3 {
  padding-left: 30px;
}

/* .site-form-in-drawer-wrapper {
	position: absolute;
	right: 0px;
	bottom: 0px;
	width: 100%;
	padding: 10px 16px;
	border-top: 1px solid #e9e9e9;
	background: #fff;
	text-align: right;
} */

/* #components-layout-demo-responsive .logo {
	height: 32px;
	background: rgba(255, 255, 255, 0.2);
	margin: 16px;
  } */
  
  /* .site-layout-sub-header-background {
	background: #fff;
  }
  
  .site-layout-background {
	background: #fff;
  } */

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}  */
/* 
#components-layout-demo-responsive .logo {
	height: 32px;
	background: rgba(255, 255, 255, 0.2);
	margin: 16px;
}

.site-layout-sub-header-background {
	background: #fff;
}

.site-layout-background {
	background: #fff;
} */


/* DESC HEADERS */
.ant-descriptions-row .ant-descriptions-item-label {
  background-color: #e6f7ff;
}